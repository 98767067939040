.landing-page {
    &__wrapper {
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;


        h1 {
            font-size: 60px;
            margin-top: 50px;
        }
    }

    &__header {
        height: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 50px;
        margin-top: 25px;

        &__image {
            height: 70%;

            &:first-of-type {
                height: 60%;
            }
        }
    }

    &__content {
        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 50%;
            width: 70%;
            padding: 50px 0;
        }

        &__qr {
            &__wrapper {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 35%;
                gap: 50px;
            }

            &__arrow {
                position: absolute;
                right: 50px;
                top: 120px;
            }

            &__text {
                color: #F1AF07;
                font-size: 40px;
            }

            &__code {
                width: 60%;
                height: auto;
            }
        }

        &__illu {
            height: 110%;
            margin-right: -50px;
        }
    }
}

.quizz-page {
    &__wrapper {
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}