.company-selector {
    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: flex-start;
    }

    &__company-wrapper {
        width: 100%;
    }

    &__border {
        border-bottom: 2px solid white;
        width: 100%;
        margin: 50px 0;
    }

    &__company-button {

        border: none;
        border-radius: 8px;
        box-shadow: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        width: 300px;
        max-width: 90%;
        margin: 25px auto;

        &.devduck {
            background: #f0ad2b;
        }

        &.duckeneers {
            background: #ffb92d;
        }

        img {
            height: 2rem;
        }
    }
}

.quizz-content {
    &__wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__company {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
        align-items: center;


        img {
            height: 1.5rem;
            width: auto;
        }
    }

    &__question {
        &__wrapper {
            height: 100%;
            width: 100%;
            overflow: scroll;
        }

        &__progress {
            margin: 0 auto;
            margin-top: 25px;
            margin-bottom: 50px;
            position: relative;
            display: flex;
            justify-content: space-evenly;

            &__dot {
                width: 0.5rem;
                height: 0.5rem;
                background-color: #1f1f1f;
                border-radius: 50%;
            }

            &__bar {
                border-radius: 0.25rem;
                height: 0.5rem;
                transition: all ease-in-out 250ms;
                position: absolute;
                left: -0.25rem;
                top: 0;

                &.devduck {
                    background: #ffb92d;
                }

                &.duckeneers {
                    background: #ffb92d;
                }
            }
        }

        &__title {
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            width: 70%;
            margin: 50px auto;
        }
    }
}

.answer-button {
    &__wrapper {
        border: none;
        border-radius: 8px;
        box-shadow: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        width: 250px;
        max-width: 90%;
        margin: 25px auto;

        &.devduck {
            background: #f0ad2b;
        }

        &.duckeneers {
            background: #ffb92d;
        }
    }
}

.end-screen {
    &__wrapper {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            input,
            input:focus,
            input:active {
                border: 1px solid #eaeaea;
                margin: 15px 0;
                border-radius: 10px;
                box-sizing: border-box;
                font-family: Montserrat;
                font-size: inherit;
                padding: 15px;
                resize: none;
                width: 100%;
                color: inherit;
            }
        }

        .checkbox-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            gap: 15px;
            font-size: 0.8rem;
            margin: 0 auto;
        }

        input[type="checkbox"] {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        .checkbox {
            display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
            height: 2rem;
            width: 2rem;
            background: unset;
            border: 2px #f4f4f4 solid;
            margin-right: 4px;

            &-label {
                width: fit-content;
            }
        }

        .checkbox--active {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: dash 5s linear forwards;

            &.devduck {
                border-color: #ffb92d;
                background: #ffb92d;
            }

            &.duckeneers {
                border-color: #ffb92d;
                background: #ffb92d;
            }
        }

        @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
        }

    }

    &__disclaimer {
        font-size: small;
        text-align: center;
    }

    &__done,
    &__content,
    &__disclaimer,
    &__submit {
        text-align: center;

        &.devduck {
            color: #ffb92d;
            border-color: #ffb92d;
        }

        &.duckeneers {
            color: #ffb92d;
            border-color: #ffb92d;
        }
    }

    &__title {
        font-weight: bold;
    }

    &__submit {
        border: none;
        border-radius: 8px;
        box-shadow: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        width: 250px;
        max-width: 90%;
        color: #1f1f1f !important;
        background: #f0ad2b;
    }

    &__skip {
        text-decoration: underline;
        margin-top: 50px;
        font-size: small;
    }

}

.win-screen {
    &__wrapper {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 30px;

    }

    &__title {
        font-weight: 600;
    }

}