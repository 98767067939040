button {
    color: black;
}

#root {
    height: 100%;
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: #f3f3f3;
    box-sizing: content-box;
}

* {
    user-select: none;
}

body {
    font-family: "Muli";
    background: linear-gradient(#1f1f1f, #000000);
}

h1 {
    font-weight: 700;
}

input,
input:focus,
input:active {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid;
    color: white;
    outline: none;

    &.devduck {
        border-color: #ffb92d;
    }

    &.duckeneers {
        border-color: #ffb92d;
    }
}

.yellow-color {
    color: #ffb92d;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 15px;
    font-size: 0.8rem;
    margin: 0 auto;
}

input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
    height: 20px;
    width: 50px;
    background: black;
    border: 2px #ddd solid;
    margin-right: 4px;
}

.checkbox--active {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 5s linear forwards;

    &.devduck {
        border-color: #ffb92d;
        background: #ffb92d;
    }

    &.duckeneers {
        border-color: #ffb92d;
        background: #ffb92d;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.privacy-footer {
    font-size: 0.5rem;
    color: lightgray;
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}


/* === WELCOME PARTY === */

body {
    background: #000000;
}

.welcome-party {
    &__wrapper {
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: relative;
        padding: 0 25px;
        padding-bottom: 200px;
        overflow: scroll;
        max-width: 800px;
        margin: 0 auto;
    }

    &__header {
        margin-top: 50px;
    }

    &__intro {
        line-height: 150%;
    }

    &__form {
        width: 90%;
        padding: 0 5%;
    }

    &__input,
    &__input:focus,
    &__input:active {
        width: 90%;
        margin: 0 auto;
        border: 1px solid #F2B00A;
        margin: 10px 0;
        padding: 10px 15px;
        border-radius: 60px;
    }

    // &__checkbox {
    //     margin: 15px 0;

    //     a {
    //         text-decoration: none;
    //         color: #F2B00A;
    //     }

    // }

    &__button {
        border: 1px solid #F2B00A;
        color: #F2B00A;
        text-transform: uppercase;
        border-radius: 60px;
        text-align: center;
        width: 75%;
        margin: 0 auto;
        padding: 10px;
        cursor: pointer;

        &.with-margin {
            margin: 15px auto;
        }

        a {
            color: inherit;
            text-decoration: none;
            width: 100%;
            display: block;
        }
    }

    &__image {
        width: 50%;
        height: auto;
        margin: 50px auto;
    }

    &__footer {
        background: #211e1f;
        height: 50px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        a {
            margin: 15px;
            color: white;
            text-decoration: none;
            font-size: smaller;
        }
    }
}

.workshop {
    &__wrapper {
        background: white;
        color: black;
        font-family: "Montserrat";
        scroll-behavior: smooth;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        position: relative;
    }

    &__win-icon {
        height: 3rem;
    }

    &__button {
        background: #f0ad2b;
        border: none;
        border-radius: 8px;
        box-shadow: none;
        cursor: pointer;
        padding: 15px;
        text-align: center;
        width: 250px;
        max-width: 90%;
        margin: 25px auto;
    }

    &__content {
        padding: 0 15px;
        padding-bottom: 100px;
        flex-grow: 1;
        position: relative;
        line-height: 150%;

        h2 {
            font-weight: 600;
            font-size: 1.3rem;
        }

        input,
        input:focus,
        input:active {
            border: 1px solid #eaeaea;
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Montserrat;
            font-size: inherit;
            padding: 15px;
            resize: none;
            width: 100%;
            color: inherit;
        }


        .checkbox-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            gap: 15px;
            font-size: 0.8rem;
            margin: 0 auto;
        }

        input[type="checkbox"] {
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        .checkbox {
            display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
            height: 2rem;
            width: 2rem;
            background: unset;
            border: 2px #f4f4f4 solid;
            margin-right: 4px;
        }

        .checkbox--active {
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: dash 5s linear forwards;

            &.devduck {
                border-color: #ffb92d;
                background: #ffb92d;
            }

            &.duckeneers {
                border-color: #ffb92d;
                background: #ffb92d;
            }
        }

        @keyframes dash {
            to {
                stroke-dashoffset: 0;
            }
        }
    }

    &__header {
        height: max-content;
        position: sticky;
        top: 0;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        z-index: 10;

        &__image {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &__title {
            z-index: 10;
            position: relative;
            color: black;
            font-weight: 600;
            max-width: 75%;
            margin-right: 15px;
            font-size: 2rem;
        }

        &__icon {
            z-index: 10;
            position: relative;
            height: 3rem;
            width: auto;
        }
    }

    &__footer {
        background: #211e1f;
        height: 100px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__logo img {
            margin: 15px 0;
            height: 1rem;
            width: auto;
        }


        &__links {}

        a {
            margin: 15px;
            color: white;
            text-decoration: none;
            font-size: smaller;
        }
    }
}